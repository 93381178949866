<template>
    <li>
        <div class="credit-card">
            <div class="credit-card-inner">
                <div class="inner-container">
                    <PrismicImage
                        :img="lender.data.logo"
                        w="150"
                    />

                    <ButtonTarget
                        :link="lender"
                        :placement="placement"
                        :position="position"
                        :text="$translate('cta_credit_card_list', 'Till ansökan')"
                        background="green"
                        size="md"
                        icon="fas fa-lock white"
                    />
                </div>
            </div>

            <div class="credit-card-inner">
                <div class="container header">
                    <div class="header-inner">
                        <h3>{{ lender.data.title }}</h3>
                        <span class="tag">{{ lender.data.label }}</span>
                    </div>
                </div>

                <div class="container card-info">
                    <ListCardInfo 
                        :model="model" 
                        :template="template" 
                    />
                </div>

                <ul
                    class="container list"
                >
                    <li
                        v-for="(pro, index) in lender.data.pros.map(iterator => iterator.pro)"
                        :key="`pro${index}`"
                        class="list-icon list-check"
                    >
                        {{ pro }}
                    </li>
                </ul>
            </div>
        </div>

        <span
            class="compliance text-subtile"
        >
            {{ model.complianceExample }}
        </span>
    </li>
</template>
<script>
import ListCardInfo from '@/components/slices/lists/LenderListCardInfo.vue';
export default {
    components: {
        ListCardInfo
    },
    props: {
        lender: {
            type: Object,
            required: true
        },
        template: {
            type: String,
            required: false,
            default: 'credit_account'
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        model() {
            return new this.$models.Loan(this.lender);
        }
    }
};
</script>
<style lang="scss" scoped>
// copied from @/components/cards/CreditCard.vue
.credit-card.show {
    animation: show .3s ease-in forwards;
}
@keyframes show {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
    .credit-card {
        @include border-radius(5px);
        box-shadow: $bxs;
        @include flex(center, start);
        @include flex(center, stretch);
        overflow: hidden;


        &-inner {
            width: 100%;
            @include flex(center,start);
            @include spacing(padding, 5);

            @include device(pad) {
                width: auto;
            }

            .inner-container {
                @include flex;
                @include grid(1, 10px);

                width: 100%;
                img {
                    margin: 0 auto 15px;
                }

            }

            .container { width: 100%; }

            .lined { border-bottom: 3px solid $pink; }
        }

        &-inner:nth-child(1) {
            text-align: center;
            max-width: 320px;

            @include device(pad) {
                flex: 1;
                max-width: 330px;
            }

            img { height: auto; }
        }

        &-inner:nth-child(2) {
            @include grid(1, 25px);
            background: $lightblue;
            @include device(pad) { flex: 2; }

            .container.header {
                @include grid(1, 20px);

                @include device(pad) {
                    @include flex(between,start);
                }

                .header-inner {
                    text-align: center;

                    @include device(pad) {
                        text-align: left;
                    }

                    &:first-child {
                        @include grid(1, 10px);
                    }

                    &:last-child {
                        @include flex;
                        @include device(pad) {
                            @include flex(between);
                        }
                    }

                    span {
                        display: inline;
                        @include spacing(margin,3,right);
                    }

                    .checkbox {
                        @include flex;
                        @include size(20px);
                        display: inline-block;
                        border: 2px solid $pink;
                        border-radius: 3px;

                        i {
                            @include font-size(20px, 0px);
                            color: $green;
                        }
                    }

                    .tag {
                        margin-left: 5px;
                        font-family: $fontText;
                        background: $pink;
                        color: white;
                        padding: 5px 10px;
                        border-radius: 30px;
                        font-size: 0.8em;
                    }
                }
            }

            .container.options {
                @include flex(start);

                li {
                    @include spacing(margin, 4, right);
                    @include font-size(16px);
                    cursor: pointer;

                    @include device(pad) {
                        @include font-size(18px);
                    }
                }
            }

            .container.list {
                @include grid(1, 10px);

                li {
                    font-family: $fontText;
                    @include font-size(14px);
                }
            }

            .list.details {
                gap: 0;

                li {
                    @include flex(between);
                    @include spacing(padding, 2);

                    span {
                        &:last-child {
                            font-weight: bold;
                            font-family: $fontHeader;
                            text-align: right;

                            @media screen and (max-width: 400px) {
                                width: 100%;
                                text-align: left;
                                padding-top: 10px;
                            }

                            @include device(pad) {
                                flex: 1;
                                width: auto;
                            }
                        }
                    }

                    i.fa-check-circle {
                        color: $green;
                    }
                    i.fa-times-circle {
                        color: $red;
                    }
                }

                li:nth-child(odd) { background: $white; }
            }

            span.header {
                font-family: $fontText;
                @include spacing(padding, 2);
                @include font-size(14px);
                display: block;
                margin-top: 15px;
                font-weight: bold;
            }

            .container.review {
                @include flex(start,start);
                width: 100%;

                p {
                    @include font-size(14px);
                }

                .btn { @include spacing(margin, 6, top);}
            }
        }

        &-inner:nth-child(3) {
            @include grid(1, 10px);
            text-align: center;

            @include device(pad) {
                display: none;
            }
        }
    }


    .compliance {
        @include spacing(margin, 2, top);
        display: block;
    }
</style>
