<template>
    <ol class="toplist">
        <li 
            v-for="(item, index) in items" 
            :key="index" 
            class="toplist-wrapper"
        >
            <div>
                <span class="toplist-number">
                    {{ index + 1 }}
                </span>
            </div>
            <div>
                <span
                    v-scroll-to="{
                        el: `#bigListItem${sliceId + index}`,
                        offset: -100  
                    }"
                    class="toplist-card-title"
                >
                    {{ item.topListTitle }}
                </span>
                <div 
                    v-if="$validateText(item.toplistText)"
                    class="toplist-rich-text rich-text"
                    v-html="topListText(item)"    
                />
            </div>
        </li>
    </ol>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        sliceId: {
            type: String,
            required: true
        }
    },
    methods: {
        topListText(item) {  
            return this.$prismic.asHtml(item.toplistText)
                .replace(/{interest}/ig, item.model.interestString)
                .replace(/{effectiveInterest}/ig, item.model.effectiveInterestString)
                .replace(/{yearlyCost}/ig, item.model.yearlyCostString)
                .replace(/{maxCredit}/ig, item.model.getMaxCreditString())
                .replace(/{interestFreeDays}/ig, item.model.interestFreeDays);
            
        }
    }
};
</script>
<style lang="scss" scoped>
    .toplist {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include border(bottom);
        margin-top: 1rem;
        padding-bottom: 2rem;
        &-wrapper {
            display: flex;
            align-items: baseline;
            .toplist-number {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.5rem; 
                font-weight: bold;
                color: $darkgray;
                width: 1.75rem;
                height: 1.75rem;
                border-radius: 50%;
                background-color: $lightgray;
            }
            .toplist-card-title {
                cursor: pointer;
                color: $pink;
                font-family: $fontHeader;
                font-weight: bold;
                font-size: 18px;
                margin-right: 0.25rem;
                &:hover {
                    color: $red;
                }
            }
    
            .toplist-rich-text::v-deep {
                display: inline;
                p {
                    display: inline;
                }
            }
        }
    }
</style>