<template>
    <div class="site">
        <component
            :is="sliceType(document.type)"
            :document="document"
            :hreflangs="hreflangs || {}"
            :url="canonical"
        />

        <script type="application/ld+json" v-html="jsonld" />
    </div>
</template>

<script>
import Page from '@/components/pages/Page.vue';
import Post from '@/components/pages/Post.vue';
import Author from '@/components/pages/Author.vue';
import CreditCard from '@/components/pages/credit-card/CreditCardPage.vue';
import getDocument from '@/plugins/get-document';
export default {
    components: { 
        Page, 
        Post, 
        CreditCard, 
        Author 
    },
    layout: 'site',
    async middleware({ store, $prismic }) {
        await store.dispatch('fetchStates', $prismic);
    },

    /**
    * asyncData used on server side (only dev)
    */
    async asyncData({$prismic, route, error}) {
        try {
            return await getDocument($prismic, route.path, error);
        }
        catch (e) {
            error({statusCode: 404, message: 'Not found'});
        }
    },
    head() {
        return {
            title: this.document.data.seo_title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.document.data.seo_description
                }
            ]
                .concat(this.document.data.is_landing_page === true ? [{ name: 'robots', content: 'noindex' }] : []),
            link: [
                {
                    rel: 'canonical',
                    href: this.canonical
                },
                {
                    rel: 'shortcut icon',
                    href: this.$store.state.settings.favico.small.url
                }
            ]
                .concat(this.hreflangs)
        };
    },
    computed: {
        canonical: function() {
            return this.$prismic.linkResolver(this.document, true);
        },
        jsonld: function() {
            return {
                '@context': 'https://schema.org',
                '@type': 'WebPage',
                'name': this.document.data.title,
                'dateModified': this.$format.date(this.document.last_publication_date)
            };
        }
    },
    methods: {
        sliceType(type) {
            if (type.includes('_')) {
                var split = type.split('_')
                for(var i = 0; i < split.length; i++) {
                    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
                }
                return split.join("");
            }

            return type
        }
    }
};
</script>
