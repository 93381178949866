<template>
    <ul>
        <template v-if="$isGermany()">
            <li>
                <span>{{ $translate('card_type', 'Korttyp') }}</span>
                <span v-if="model.isDebit">{{ $translate('prepaid_card', 'Prepaid') }}</span>
                <span v-else-if="model.isAggregator">{{ $translate('aggregator_card', 'Prepaid') }}</span>
                <span v-else-if="model.isCredit">{{ $translate('credit_card', 'Kreditkort') }}</span>
            </li>
            <li>
                <span>{{ $translate('yearly_fee', 'Årskostnad') }}</span>
                <span v-html="model.yearlyCostHtml" />
            </li>
            <li>
                <span>{{ $translate('effective_rate', 'Effektiv ränta') }}</span>
                <span v-if="model.isCredit">{{ model.effectiveInterestString }}</span>
                <span v-else>-</span>
            </li>
            <li>
                <span>{{ $translate('withdrawal_fee', 'Uttagsavgift') }}</span>
                <span>{{ model.getWithdrawalFeeString() }}</span>
            </li>
            <li>
                <span>{{ $translate('currency_exchange_fee', 'Valutapåslag') }}</span>
                <span>{{ model.getCurrencyExchangeFeeString() }}</span>
            </li>
            <li>
                <span>{{ $translate('mobile_payments') }}</span>
                <span class="icons">
                    <i v-if="model.hasApplePay" class="fab fa-apple-pay" />
                    <i v-if="model.hasGooglePay" class="fab fa-google-pay" />
                    <span v-if="!(model.hasApplePay || model.hasGooglePay)">-</span>
                </span>
            </li>
        </template>
        <template v-else>
            <li>
                <span>{{ $translate('max_credit', 'Maxkredit') }}</span>
                <span v-if="model.isDebit">{{ $translate('prepaid_card', 'Prepaid') }}</span>
                <span v-else-if="model.isAggregator">{{ $translate('aggregator_card', 'Prepaid') }}</span>
                <span v-else>{{ model.getMaxCreditString($translate('no_max_credit', 'Ingen kreditgräns')) }}</span>
            </li>

            <li>
                <span>{{ $translate('yearly_fee', 'Årskostnad') }}</span>
                <span v-html="model.yearlyCostHtml" />
            </li>
            <li>
                <span>{{ $translate('effective_rate', 'Effektiv ränta') }}</span>
                <span>{{ model.effectiveInterestString }}</span>
            </li>
            <li v-if="($isSweden() || $isNorway())" class="card-info-rate-free-days">
                <span>{{ $translate('rate_free_days', 'Räntefria dagar') }}</span>
                <span>
                    {{ 
                        model.getInterestFreeDaysString(
                            $translate('core_models_rate_free_days', '{days} dagar')
                        ) 
                    }}
                </span>
            </li>

            <!-- TODO: Make functionality for all markets -->
            <template v-if="($isSweden() || $isNorway()) && isBonusList">
                <li>
                    <span>{{ $translate('bonus_program', 'Bonusprogram') }}</span>
                    <i class="fas" :class="model.hasBonusProgram ? 'fa-check-circle' : 'fa-times-circle'" />
                </li>
                <li>
                    <span>{{ $translate('cashback', 'Cashback') }}</span>
                    <i v-if="! model.hasCashback" class="fas fa-times-circle" />
                    <span v-else class="description">{{ cashbackDescription }}</span>
                </li>
                <li>
                    <span>{{ $translate('discounts', 'Rabatter') }}</span>
                    <i v-if="! model.hasDiscountProgram" class="fas fa-times-circle" />
                    <span v-else class="description">
                        {{ 
                            model.getDiscountString(
                                $translate('core_models_discount_percent_in_stores', 'Upp till {percentage} i {count} butiker')
                            ) 
                        }}
                    </span>
                </li>
            </template>
            <template v-else-if="($isSweden() || $isNorway()) && isTravelList">
                <li>
                    <span>{{ $translate('ct_travel_insurance', 'Reseförsäkring') }}</span>
                    <i :class="model.hasTravelInsurance ? 'fas fa-check-circle' : 'fas fa-times-circle'" />
                </li>
                <li>
                    <span>{{ $translate('currency_exchange_fee', 'Valutapåslag') }}</span>
                    <span>{{ model.getCurrencyExchangeFeeString() }}</span>
                </li>
                <li>
                    <span>{{ $translate('airline_bonus', 'Flygbonus') }}</span>
                    <i :class="model.hasAirlineBonus ? 'fas fa-check-circle' : 'fas fa-times-circle'" />
                </li>
            </template>
            <template v-else-if="($isSweden() || $isNorway()) && isFuelList && model.hasFuelOffers">
                <li>
                    <span>{{ $translate('fuel_chain_discount', 'Rabatt på drivmedelskedja') }}</span>
                    <span>{{ model.fuelOffersChain }}</span>
                </li>
                <li>
                    <span>{{ $translate('manned_stations_discount', 'Rabatt på bemannade stationer') }} </span>
                    <i v-if="! Boolean(model.fuelDiscountManned)" class="fas fa-times-circle" />
                    <span v-else>
                        {{ 
                            model.getFuelDiscountMannedString(
                                $translate('core_models_fuel_per_liter_discount', '{discount} öre / liter')
                            ) 
                        }}
                    </span>
                </li>
                <li>
                    <span>{{ $translate('unmanned_stations_discount', 'Rabatt på obemannade stationer') }}</span>
                    <i v-if="! Boolean(model.fuelDiscountUnmanned)" class="fas fa-times-circle" />
                    <span v-else>
                        {{ 
                            model.getFuelDiscountUnmannedString(
                                $translate('core_models_fuel_per_liter_discount', '{discount} öre / liter')
                            ) 
                        }}
                    </span>
                </li>
            </template>
            <template v-if="($isSweden() || $isNorway()) && isShoppingList">
                <li>
                    <span>
                        {{ $translate('bonus_cashback_discounts', 'Bonus, Cashback eller Rabatter') }}
                    </span>
                    <i 
                        class="fas" 
                        :class="model.hasBonusProgram || 
                            model.hasCashback || 
                            model.hasDiscountProgram ?
                                'fa-check-circle' :
                                'fa-times-circle'"
                    />
                </li>
                <li>
                    <span>{{ $translate('shopping_insurances', 'Shoppingförsäkringar') }}</span>
                    <span v-if="shoppingInsurancesAmount(model)">
                        {{ shoppingInsurancesAmount(model) }}
                    </span>
                    <i v-else class="fas fa-times-circle" />
                </li>                
                <li>
                    <span>{{ $translate('rate_free_days', 'Räntefria dagar') }}</span>
                    <span v-if="model.interestFreeDays" class="description">
                        {{ 
                            model.getInterestFreeDaysString(
                                $translate('core_models_rate_free_days', '{days} dagar.')
                            )
                        }}
                    </span>
                    <i v-else class="fas fa-times-circle" />
                </li>
            </template>
        </template>
    </ul>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true,
        },
        template: {
            type: String,
            required: false,
            default: 'default'
        }
    },
    computed: {
        cashbackDescription() {
            if (this.model.hasUnlimitedCashback) {
                return this.$format.percentage(this.model.cashbackPercentage);
            }

            return `${this.$format.percentage(this.model.cashbackPercentage)} (max ${this.model.getCashbackLimitString(
                this.$translate('month_short', 'mån', this.$translate('year_short', 'år'))
            )})`;
        },
        isBonusList() {
            return this.template === 'bonus';
        },
        isTravelList() {
            return this.template === 'travel';
        },
        isFuelList() {
            return this.template === 'fuel';
        },
        isShoppingList() {
            return this.template === 'shopping';
        }
    },
    methods: {
        shoppingInsurancesAmount(model) {
            let shoppingInsurances = 0;
            if (model.hasDeliveryInsurance) {
                shoppingInsurances++;
            }
            if (model.hasExtendedWarranty) {
                shoppingInsurances++;
            }
            if (model.hasPurchaseProtection) {
                shoppingInsurances++;
            }
            if (model.hasPriceGuarantee) {
                shoppingInsurances++;
            }
            return shoppingInsurances;
        }
    }
};
</script>
<style lang="scss" scoped>

    i.fa-check-circle {
        color: $green;
        text-align: right;
        @include device(pad) {
            text-align: left;
        }
    }
    i.fa-times-circle {
        color: $red;
        text-align: right;
        @include device(pad) {
            text-align: left;
        }
    }
    ul {
        @include grid(1, 3px);
        @include font-size(14px, 0px);
        @include device(pad) {
            @include grid(3, 3px);
        }

        .card-info-rate-free-days {
            @include device(pad) {
                display: none;
            }
        }

        li {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            row-gap: 0px;
            column-gap: 5px;
            @include spacing(padding, 3);
            text-align: left;
            background: $white;

            @include device(pad) {
                @include spacing(padding, 2);
                flex-direction: column;
                align-items: flex-start;
                row-gap: 10px;
                column-gap: 0px;
            }

            span {
                font-family: $fontText;
            }
            span:first-child {
                font-weight: normal;
                font-size: .7em;
                text-transform: uppercase;
            }
            span:last-child {
                text-align: right;
                font-weight: bold;

                @include device(pad) { 
                    text-align: left; 
                }

                &.icons {
                    margin-top: -4px;
                    margin-bottom: -4px;
                    i {
                        font-size: 22px;
                        margin-right: 5px;
                    }

                    span {
                        font-size: 14px;
                    }
                }
            }
            span.description {
                line-height: 1rem !important;
            }
        }
    }

    ul.narrow {
        @include device(pad) {
            @include grid(2, 0px);
        }
    }
</style>
