import { replace as replaceHelper, getHrefLangs } from '@swegaming-ab/nuxtjs-helpers';

export default async function($prismic, path, error, documentKey = 'slug') {

    const documentHelper = findDocumentHelper(path, documentKey);

    if (documentHelper) {

        const fetchLinks = ['page.icon', 'page.image', 'page.parent', 'page.title']
            .concat(getFetchLinks(documentHelper.pageType));

        let document = await $prismic.api.getByID(documentHelper.id, {
            fetchLinks: fetchLinks
        });

        document = replaceHelper(document, document.lang);

        return {
            document: document,
            hreflangs: getHrefLangsHelper(document, $prismic.linkResolver)
        };
    }

    return error({
        statusCode: 404,
        message: 'Not found'
    });
}

function findDocumentHelper(path, key = 'slug') {

    if (path.slice(-1) !== '/') {
        path = `${path}/`;
    }

    const documents = require('@/documents.json');

    for (const [, document] of Object.entries(documents)) {
        if (document.prismicLocale === process.env.PRISMIC_LANG && document[key] === path) {
            return document;
        }
    }
    return false;
}


function getFetchLinks(pageType) {
    return {
        'credit_card': ['credit_card.image', 'credit_card_feature.image', 'credit_card_feature.title', 'credit_card_feature.related_page'],
        'page': ['author.name', 'author.image', 'author.bio', 'author.email', 'lender.title', 'lender.logo', 'lender.pros', 'lender.core_api', 'lender.label'],
        'post': ['author.name', 'author.image', 'author.bio', 'author.email']
    }[pageType] || [];
}

function getHrefLangsHelper(document, linkResolver) {

    let siteConfig = require('@/config/locales.config.js').default;
    let prismics = siteConfig.map(locale => {
        return locale.prismic;
    });

    let prismicToHreflangMap = new Map();
    siteConfig.forEach(c => {
        prismicToHreflangMap.set(c.prismic, c.hreflang);
    });

    return getHrefLangs(document, linkResolver, prismics, prismicToHreflangMap);
}
