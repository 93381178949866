<template>
    <Section width="lg" :style="textAlign">
        <div class="container">
            <span class="container-header">{{ $prismic.asText(slice.items[0].header) }}</span>

            <div
                class="container-content rich-text"
                v-html="$prismic.asHtml(slice.items[0].text)"
            />
        </div>

        <div class="container">
            <span class="container-header">{{ $prismic.asText(slice.items[1].header) }}</span>

            <div
                class="container-content rich-text"
                v-html="$prismic.asHtml(slice.items[1].text)"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        textAlign() {
            if (this.slice.primary.text_align) {
                return `text-align:${this.slice.primary.text_align};`;
            }
            return 'text-align:center;';
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {

        .section-inner {
            @include flex(center, stretch);
            box-shadow: $bxs;
            @include border-radius(3px);
            overflow: hidden;

            .container {
                width: 100%;
                display: flex;
                flex-direction: column;
                @include spacing(padding, 5);
                @include device(pad) {
                    width: 50%;
                    @include spacing(padding, 8);
                }

                &-header {
                    font-family: $fontHeader;
                    @include font-size(32px, 10px);
                    @include spacing(margin,6,bottom);
                }

                &-content {
                    p:first-child { margin-top: 0; }
                    p:last-child { margin-bottom: 0; }
                }

                &:first-child { background: $lightblue; }
                &:last-child { background: $lightpink; }
            }
        }
    }
</style>
