<template>
    <div class="wrapper">
        <div class="card-top-section">
            <nuxt-link
                :to="$prismic.linkResolver(item.card)"
                class="card-link h3"
            >
                <div class="title-wrapper">
                    {{ index + 1 }}.
                    <h3 class="title">
                        {{ item.h3 }}
                    </h3>
                </div>
                <PrismicImage
                    :img="item.card.data.card_image"
                    class="card-image"
                    w="132"
                    h="84"
                />
            </nuxt-link>
            <div 
                v-if="$validateText(item.teaser)"    
                class="rich-text card-teaser" 
                v-html="$prismic.asHtml(item.teaser)"
            />
        </div>

        <table-data 
            v-if="dataType === 'card-data'" 
            :table-data="item.tableData"    
        />

        <pros-list 
            v-else 
            :pros="item.pros"    
        />

        <ButtonTarget
            v-if="item.card.data.redirect_enabled"
            :link="item.card"
            :text="$translate('cta_credit_card_page', 'Ansök nu')"
            icon="fas fa-chevrons-right fa-2xs white"
            background="green"
            size="md"
            class="card-out-link"
        />
    </div>
</template>

<script>
import TableData from './TableData.vue';
import ProsList from './ProsList.vue';
export default {
    components: {
        TableData,
        ProsList
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        dataType: {
            type: String,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
    .wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        padding-bottom: 2rem;
        @include border(bottom);
        @include device(pad) {
            margin-top: 3rem;
            padding-bottom: 3rem;
        }

        .card-top-section {
            @include device(mobile) {
                display: flex;
                flex-direction: column;

                .card-link {
                    order: -1;
                }
            }

            .card-link {
                color: $font;
                &:hover {
                    .title {
                        color: $red;
                    }
                }
                .title-wrapper {
                    display: inline-flex;
                    @include device(mobile) {
                        width: 100%;
                    }
                    .title {
                        margin-left: 0.25em;
                    }

                }
            }
            
            .card-image {
                margin-left: 0;
                margin-top: 1rem;
                float: none;
                @include device(pad) {
                    float: right;
                    margin-left: 1rem;
                    margin-top: 0;
                }
            }
        }
        .card-out-link {
            width: fit-content;
            margin-top: 2rem;
        }
    }
</style>