var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Section',{attrs:{"width":"lg"}},[(_vm.$prismic.asHtml(_vm.slice.primary.title))?_c('div',{staticClass:"header",domProps:{"innerHTML":_vm._s(_vm.$prismic.asHtml(_vm.slice.primary.title))}}):_vm._e(),_vm._v(" "),(_vm.slice.primary.p)?_c('p',{staticClass:"teaser"},[_vm._v(_vm._s(_vm.slice.primary.p))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"table-container mobile-scroll-shadow"},[_c('table',{staticClass:"table-container__table"},[_c('thead',[_c('tr',[_c('th'),_vm._v(" "),_vm._l((_vm.allCards),function(card){return _c('th',{key:`logo${card.id}`},[_c('nuxt-link',{attrs:{"to":_vm.$prismic.linkResolver(card)}},[_c('prismic-image',{attrs:{"img":card.data.card_image,"w":"200"}})],1)],1)})],2),_vm._v(" "),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$translate('credit_card', 'Kreditkort')))]),_vm._v(" "),_vm._l((_vm.allCards),function(card){return _c('th',{key:`title${card.id}`},[_vm._v("\n                        "+_vm._s(card.data.title)+"\n                    ")])})],2)]),_vm._v(" "),_c('tbody',[(!_vm.slice.primary.disable_general_info)?[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$translate('yearly_fee', 'Årsavgift')))]),_vm._v(" "),_vm._l((_vm.allCards),function(card,index){return _c('td',{key:index,class:{ 
                                highlight: _vm.checkLowest('yearlyCost', card)
                            },domProps:{"innerHTML":_vm._s(card.model.yearlyCostHtml)}})})],2),_vm._v(" "),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$translate('effective_rate', 'Effektiv ränta')))]),_vm._v(" "),_vm._l((_vm.allCards),function(card,index){return _c('td',{key:index,class:{ 
                                highlight: _vm.checkLowest('minEffectiveInterestPossible', card) && card.model.isCredit
                            },domProps:{"innerHTML":_vm._s(_vm.effectiveInterest(card))}})})],2),_vm._v(" "),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$translate('max_credit', 'Max kredit')))]),_vm._v(" "),_vm._l((_vm.allCards),function(card){return _c('td',{key:`maxCredit${card.id}`,class:{ 
                                highlight: _vm.checkHighest('maxCredit', card) && card.model.isCredit 
                            },domProps:{"innerHTML":_vm._s(_vm.maxCredit(card))}})})],2)]:_vm._e(),_vm._v(" "),(_vm.slice.primary.table_type === 'fees')?_vm._l((_vm.fees),function(fee,index){return _c('Fees',{key:index,attrs:{"fee":fee,"all-cards":_vm.allCards,"check-lowest":_vm.checkLowest}})}):(_vm.slice.primary.table_type === 'mobile payment')?[_c('MobilePayments',{attrs:{"all-cards":_vm.allCards}})]:(_vm.slice.primary.table_type === 'insurances')?_vm._l((_vm.insurancesToShow),function(insurance,index){return _c('Insurances',{key:index,attrs:{"insurance":insurance}})}):(_vm.slice.primary.table_type === 'pros&cons')?[_c('Pros',{attrs:{"all-cards":_vm.allCards}}),_vm._v(" "),_c('Cons',{attrs:{"all-cards":_vm.allCards}})]:_vm._e(),_vm._v(" "),_vm._l((_vm.customContent),function(row,index){return _c('FreeText',{key:index,attrs:{"row":row}})}),_vm._v(" "),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$translate('ct_read_more_th', 'Läs mer')))]),_vm._v(" "),_vm._l((_vm.allCards),function(card){return _c('td',{key:`more${card.id}`},[_c('nuxt-link',{staticClass:"button-section btn btn-outline-green btn-sm text-green",attrs:{"to":_vm.$prismic.linkResolver(card)}},[_vm._v("    \n                            "+_vm._s(_vm.$translate('ct_details', 'Detaljerad info'))+"\n                        ")])],1)})],2),_vm._v(" "),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$translate('ct_to_application_th', 'Ansök')))]),_vm._v(" "),_vm._l((_vm.allCards),function(card){return _c('td',{key:`apply${card.id}`},[(card.data.redirect_enabled)?_c('ButtonTarget',{staticClass:"button-section",attrs:{"link":card,"text":_vm.$translate('ct_to_application', 'Ansök'),"background":"green","size":"sm"}}):_vm._e()],1)})],2)],2)])]),_vm._v(" "),(_vm.$validateText(_vm.slice.primary.footer_text))?_c('div',{staticClass:"rich-text footer-text",domProps:{"innerHTML":_vm._s(_vm.$prismic.asHtml(_vm.slice.primary.footer_text))}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }