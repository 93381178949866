<template>
    <Section width="lg">
        <HeaderSection :header="slice.primary.header" />

        <ul>
            <li
                v-for="(item, index) in slice.items"
                :key="index"
            >
                <PrismicImage
                    :img="item.image"
                    w="80"
                    h="80"
                    :alt="$prismic.asText(item.header)"
                />

                <div class="container">
                    <span>{{ $prismic.asText(item.header) }}</span>
                    <p>{{ $prismic.asText(item.text) }}</p>
                </div>
            </li>
        </ul>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .section-header {
        text-align: center;
        width: 100%;
    }
    ul {
        width: 100%;
        @include grid(1, 40px);
        @include spacing(margin, 10, top);

        @include device(pad) {
            @include grid(2, 40px);
        }

        li {
            @include flex;

            .container {
                text-align: center;
                padding-top: 20px;
                @include grid(1, 20px);

                @include device(desktop) {
                    text-align: left;
                    padding-top: 0;
                    padding-left: 60px;
                    flex: 1;
                }

                span {
                    font-family: $fontHeader;
                    @include font-size(24px);
                }
            }
        }
    }
</style>
