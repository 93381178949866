<template>
    <Section width="md">
        <div class="sorting">
            <button 
                :class="{active: activeSort === 'recommended'}" 
                @click="activeSort = 'recommended'"
            >
                {{ $translate('sort_recommended', 'Rekommenderade först') }}
            </button>
            <button 
                :class="{active: activeSort === 'alphabetical'}" 
                @click="activeSort = 'alphabetical'"
            >
                {{ $translate('sort_alphabetical', 'Alfabetiskt') }}
            </button>
        </div>
        <ul>
            <CreditCard
                v-for="(card, index) in cards"
                :id="card.id"
                :key="index"
                placement="list"
                :position="index + 1"
            />
        </ul>
    </Section>
</template>

<script>
import { orderBy } from 'lodash';
import CreditCard from '@/components/cards/CreditCard.vue';
export default {
    components: { CreditCard },
    props: {
        slice: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            activeSort: 'recommended'
        };
    },
    computed: {
        cards() {
            if (this.activeSort === 'recommended') {
                return this.$getOrderedList(this.$store.state.cards);
            }
            return orderBy(this.$store.state.cards, 'data.title', 'asc');
        },
    }
};
</script>

<style lang="scss" scoped>
    .section-inner {
        .sorting {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            row-gap: 0.5rem;
            margin-bottom: 1rem;
            @include font-size(16px);
            font-weight: bold;
            font-family: $fontHeader;
            button {
                all: unset;
                padding: 8px 16px;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                background: $white;
                @include border();
                border-radius: 5px;
                box-shadow: $btnShadow;
                white-space: nowrap;
                &:hover:not(.active) {
                    box-shadow: unset;
                }
                &.active {
                    border-color: $pink;
                    background: $pink;
                    color: $white;
                }
            }
        }
        ul {
            @include grid(1, 40px);
        }
    }
</style>
