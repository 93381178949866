<template>
    <Section
        width="sm"
        :label="slice.slice_label"
    >
        <HeaderSection 
            :header="slice.primary.h2" 
            :teaser="slice.primary.text" 
        />        
        <div class="grid">
            <div
                v-for="(iterator, index) in slice.items"
                :key="index"
                class="grid-item rich-text"
            >
                <PrismicImage
                    v-if="!slice.primary.show_steps"
                    :img="iterator.image1"
                />

                <span v-else>{{ index+1 }}</span>

                <div
                    class="grid-item__content"
                    v-html="$prismic.asHtml(iterator.text)"
                />
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .grid {
        @include grid(1, 40px);

        h2,
        h3,
        h4,
        h5 {
            margin-bottom: 20px;
            @include font-size(22px);
        }

        &-item {
            position: relative;
            padding-left: 25px;

            @include device(pad) {
                padding-left: 40px;
            }

            p:first-child {
                margin-top: 0;
            }

            span {
                position: absolute;
                left: 0;
                top: 0;
                font-family: $fontHeader;
                color: $pink;
                @include font-size(32px, 0);

                @include device(pad) {
                    top: -10px;
                    @include font-size(44px, 0);
                }
            }
        }
    }
}
</style>
