<template>
    <div>
        <h3>{{ $translate('result_section_h3', 'Resultat') }}</h3> 
        <div v-if="showSelectedCard" class="selected-card">
            <nuxt-link :to="$prismic.linkResolver(selectedCard)">
                <PrismicImage
                    :img="selectedCard.data.card_image"
                    w="160"
                    h="100"
                />
            </nuxt-link>
            <span>{{ selectedCard.data.title }}</span>
        </div>   
        <ul class="result-data">
            <li>
                <span>{{ $translate('calculator_result_interest', 'Ränta') }}</span>
                <span>{{ $format.percentage(interest, 2) }}</span>
            </li>
            <li>
                <span>{{ $translate('calculator_result_total_cost', 'Total kostnad') }}</span>
                <span>{{ $format.currency(interestCost) }}</span>
            </li>
            <li>
                <span>{{ $translate('calculator_result_total_to_pay', 'Total återbetalning') }} </span>
                <span>{{ $format.currency(totalToPay) }}</span>
            </li>
            <li>
                <span>{{ $translate('calculator_result_monthly_payment', 'Månadsbetalning') }}</span>
                <span>{{ $format.currency(monthlyPayment) }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        selectedCard: {
            type: Object,
            required: true,
        },
        credit: {
            type: Number,
            required: true,
        },
        duration: {
            type: Number,
            required: true,
        },
        interest: {
            type: Number,
            required: true,
        },
        showSelectedCard: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        interestCostStraightLineAmortization() {
            const monthlyInterestRate = (this.interest/100) / 12;
            let currentCost = 0;
            for (let i = this.duration; i > 0; i--) {
                currentCost += this.credit * (i/this.duration) * monthlyInterestRate;
            }
            return currentCost;
        },
        interestCostAnnuity() {
            const monthlyInterestRate = (this.interest/100) / 12;
            const monthlyPayment = (monthlyInterestRate * this.credit) / (1 - (1 + monthlyInterestRate) ** -this.duration);
            const totalInterestCost = (monthlyPayment * this.duration) - this.credit;
            return totalInterestCost || 0;
        },
        interestCost() {
            if (this.$isSweden()) {
                return this.interestCostStraightLineAmortization;
            }
            return this.interestCostAnnuity;
        },
        totalToPay() {
            return this.credit + this.interestCost;
        },
        monthlyPayment() {
            return this.totalToPay / this.duration;
        },
    }
};
</script>