<template>
    <div class="card-calculator">
        <h2 v-if="$validateText(title)">
            {{ title }}
        </h2>
        <div 
            v-if="$validateText(description)"
            class="rich-text description"
            v-html="$prismic.asHtml(description)" 
        />
        <div class="calculator-container">
            <div class="calculator-box user-inputs">
                <h3>{{ $translate('calculator_input_section_h3', 'Välj din kredit') }}</h3>    
                <div v-if="searchEnabled" class="search-container">
                    <label class="search-label">{{ $translate('calculator_search_label', 'Välj kort') }}</label>
                    <div class="search-input-container">
                        <input 
                            v-model="searchQuery" 
                            class="search-input" 
                            type="text"
                            :placeholder="$translate('calculator_search_placeholder', 'Sök efter kort')"
                        >
                        <i 
                            v-show="searchQuery"
                            class="fas fa-times clear-query" 
                            @click="searchQuery = ''" 
                        />
                    </div>
                    <div class="search-result">
                        <ul v-show="searchQuery && filteredCards.length" class="cards">
                            <li
                                v-for="card in filteredCards"
                                :key="card.id"
                                tabindex="0"
                                @click="updateSelectedCard(card)"
                                @keydown.enter.prevent="updateSelectedCard(card)"
                            >
                                <PrismicImage
                                    :img="card.data.card_image"
                                    w="64"
                                    h="40"
                                />
                                <span>{{ card.data.title }}</span>
                            </li>
                        </ul>
                        <div v-show="searchQuery && selectedCard?.data.title !== searchQuery && !filteredCards.length">
                            <p>{{ $translate('calculator_search_no_results', 'Inga resultat') }}</p>
                        </div>
                    </div>
                </div>
                <slider 
                    :label="$translate('calculator_debt_input_label', 'Kreditskuld')" 
                    :start-value="debtInputOptions.startValue" 
                    :increment="debtInputOptions.increment" 
                    :max-value="debtInputOptions.maxValue" 
                    :format-value="(value) => $format.currency(value)" 
                    @updateValue="(value) => credit = value"
                />
                <slider 
                    :label="$translate('calculator_duration_input_label', 'Avbetalningstid')" 
                    :start-value="24" 
                    :min-value="1"
                    :max-value="180" 
                    :format-value="$formatDuration" 
                    @updateValue="(value) => duration = value"
                />
                <slider 
                    v-if="model.minInterestPossible !== model.maxInterestPossible"
                    :label="$translate('calculator_interest_input_label', 'Ränta')" 
                    :start-value="model.minInterestPossible" 
                    :min-value="model.minInterestPossible"
                    :max-value="model.maxInterestPossible" 
                    increment="0.01"
                    :format-value="(value) => $format.percentage(value, 2)" 
                    @updateValue="(value) => selectedInterest = value"
                />
            </div>
            <result-box 
                class="calculator-box result"
                :interest="interest"
                :credit="credit"
                :duration="duration"
                :selected-card="selectedCard"
                :show-selected-card="searchEnabled"
            />
        </div>
        <div class="details-container">
            <div class="switch-container">
                <switch-checkbox 
                    @updateValue="(value) => showDetails = value"
                />
                <span class="switch-label">{{ $translate('calculator_show_details', 'Detaljerad betalnings nedbrytning') }}</span>
            </div>
            <details-table 
                v-if="showDetails"
                :duration="duration" 
                :credit="credit" 
                :interest="interest" 
            />
        </div>
    </div>
</template>

<script>
import Slider from '@/components/Slider.vue';
import SwitchCheckbox from '@/components/SwitchCheckbox.vue';
import ResultBox from './ResultBox.vue';
import DetailsTable from './DetailsTable.vue';
export default {
    components: {
        Slider,
        DetailsTable,
        SwitchCheckbox,
        ResultBox
    },
    props: {
        preSelectedCardId: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: Array,
            required: false,
            default: () => []
        },
        searchEnabled: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        const debtInputOptions = {
            startValue: 20000,
            increment: '1000',
            maxValue: 250000,
        };
        if (this.$isGermany() || this.$isCanada()) {
            debtInputOptions.startValue = 2000;
            debtInputOptions.increment = '100';
            debtInputOptions.maxValue = 25000;
        }
        return {
            selectedCard: this.$store.state.cards[this.preSelectedCardId],
            credit: debtInputOptions.startValue,
            duration: 24,
            searchQuery: '',
            debtInputOptions: debtInputOptions,
            showDetails: false,
            selectedInterest: null
        };
    },
    computed: {
        allCards() {
            return Object.values(this.$store.state.cards).filter(card => {
                const model = new this.$models.CreditCard(card);
                return model.isCredit;
            });
        },
        filteredCards() {
            if (!this.searchQuery) return this.allCards;
            return this.allCards.filter(card => {
                return card.data.title.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
                    (this.searchQuery !== card.data.title || this.selectedCard.uid !== card.uid);
            });
        },
        model() {
            return new this.$models.CreditCard(this.selectedCard);
        },
        interest() {
            return this.selectedInterest ?? this.model.minInterestPossible;
        },
    },
    methods: {
        updateSelectedCard(card) {
            this.selectedCard = card;
            this.selectedInterest = null;
            this.searchQuery = card.data.title;
        }
    },
};
</script>